import { React, Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { sendPostRequest, useGetRequest } from "../../Client";
import { useMutation } from "@tanstack/react-query";

export default function ChargeUpdate(props) {
  let chargeID = props.chargeID;

  // updating
  const [amount, setAmount] = useState();
  const [chargeType, setChargeType] = useState();
  const [calculation, setCalculation] = useState();
  const [orderNumber, setOrderNumber] = useState();
  const [receiverNumber, setReceiverNumber] = useState();
  const [carton, setCarton] = useState();

  const { data } = useGetRequest("charge/" + props.chargeID);

  useEffect(() => {
    setAmount(data?.charge?.amount);
    setChargeType(data?.charge?.chargeType);
    setCalculation(data?.charge?.calculation);
    setOrderNumber(data?.charge?.orderNumber);
    setReceiverNumber(data?.charge?.stockMovementID);
    setCarton(data?.charge?.processingCartonFee);
  }, [data]);

  // updating charge
  const updateCharge = async (path, body) => {
    const res = await sendPostRequest(path, body);
    props.updatedNotyf();
  };

  const { mutateAsync: doUpdateMutation } = useMutation({
    mutationFn: (data) => {
      return updateCharge(data.path, { chargeID, ...data.body });
    },
    onSuccess: () => {
      props.queryClient.invalidateQueries({ queryKey: ["charges"] });
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!amount) {
      alert("Please enter an amount");
      return;
    }

    try {
      const res = await doUpdateMutation({
        path: "charge/update",
        body: {
          chargeID: props.chargeID,
          chargeType: chargeType,
          amount: amount,
          calculation: calculation,
          orderNumber: orderNumber ? orderNumber : receiverNumber,
          stockMovementID: receiverNumber,
          processingCartonFee: carton,
        },
      });
      props.setEditOpen(false);
    } catch (e) {
      props.errorNotyf();
    }
  };

  if (data?.charge?.amount) {
    return (
      <Transition.Root show={props.editOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={props.setEditOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-lg font-medium text-gray-900"></Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="-m-2 p-2 text-gray-400 hover:text-gray-500"
                              onClick={() => props.setEditOpen(false)}
                            ></button>
                          </div>
                        </div>

                        <form onSubmit={handleSubmit}>
                          <table className="min-w-full divide-y divide-gray-300 mt-10">
                            <tbody className="divide-gray-200">
                             
                            
                            <tr>
                                <td>
                                  {" "}
                                  <label
                                    htmlFor="orderReceiver"
                                    className="block text-sm font-medium leading-6 text-gray-600 sm:pt-1.5"
                                  >
                                    Order/Receiver #
                                  </label>
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  <input
                                    type="text"
                                    name="orderReceiver"
                                    id="orderReceiver"
                                    value={orderNumber ? orderNumber : receiverNumber ? receiverNumber : ""}
                                    onChange={(e) => {
                                      setOrderNumber(e.target.value);
                                    }}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </td>
                              </tr>
                             
                              <tr>
                                <td>
                                  {" "}
                                  <label
                                    htmlFor="amount"
                                    className="block text-sm font-medium leading-6 text-gray-600 sm:pt-1.5"
                                  >
                                    Amount
                                  </label>
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  <input
                                    type="text"
                                    name="amount"
                                    id="amount"
                                    value={amount ? amount : ""}
                                    onChange={(e) => {
                                      setAmount(e.target.value);
                                    }}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  {" "}
                                  <label
                                    htmlFor="carton"
                                    className="block text-sm font-medium leading-6 text-gray-600 sm:pt-1.5"
                                  >
                                    Carton
                                  </label>
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  <input
                                    type="text"
                                    name="carton"
                                    id="carton"
                                    value={carton ? carton : ""}
                                    onChange={(e) => {
                                      setCarton(e.target.value);
                                    }}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  {" "}
                                  <label
                                    htmlFor="calculation"
                                    className="block text-sm font-medium leading-6 text-gray-600 sm:pt-1.5"
                                  >
                                    Calculation
                                  </label>
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  <textarea
                                    name="calculation"
                                    id="calculation"
                                    rows={4}
                                    value={calculation ? calculation : ""}
                                    onChange={(e) => {
                                      setCalculation(e.target.value);
                                    }}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div className="mt-5">
                            <button
                              type="submit"
                              className="flex items-center justify-center rounded-md border border-transparent bg-amber-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-amber-700"
                            >
                              Edit Charge
                            </button>
                          </div>
                        </form>
                      </div>

                      <div className="border-t border-gray-200 px-4 py-6 sm:px-6"></div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }
}
