import { React, useState } from "react";
import Navbar from "../../Components/Navbar";
import Topbar from "../../Components/Topbar";
import { useParams } from "react-router-dom";
import { sendGetRequest, sendPostRequest, useGetRequest } from "../../Client";
import ReactLoading from "react-loading";
import { Notyf } from "notyf";
import "notyf/notyf.min.css"; // for React, Vue and Svelte
import Swal from "sweetalert2";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import StockAdd from "./add";
import CustomersBreadcrumb from "../../Components/CustomersBreadcrumb";
import { Link } from "react-router-dom";
import { dateFormatter } from "../../config";

function Stock() {
  const { inventoryID } = useParams();
  const { customerID } = useParams();

  const [open, setOpen] = useState(false);

  const queryClient = useQueryClient();

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  // confirmation notifications
  const notyf = new Notyf({
    position: {
      x: "right",
      y: "top",
    },
  });

  const addedNotyf = () => {
    notyf.success("Inventory added");
  };

  // stock
  const { data: stock, isLoading } = useQuery({
    queryFn: () => sendGetRequest("stock/" + inventoryID, {}),
    queryKey: ["stock"],
  });

  const sumStock = () => {
    let total = 0;

    stock?.adjustments?.forEach((a) => {
      total += Number(a.quantity);
    });

    stock?.orders?.forEach((a) => {
        total += Number(a.quantityOrdered);
      });

      stock?.receiving?.forEach((a) => {
        total += Number(a.quantity);
      });

    return (numberWithCommas(total));
  };



  if (!isLoading) {
    return (
      <>
        <div className="flex flex-row w-full">
          <Navbar />

          <div className="flex flex-col w-4/5 bg-gray-100 ml-80">
            <Topbar />

            <nav className="flex ml-10 mt-5 h-10" aria-label="Breadcrumb">
              <ol
                role="list"
                className="flex space-x-4 rounded-md bg-white px-6 shadow"
              >
                <li className="flex">
                  <div className="flex items-center">
                    <a
                      href={`/customer/inventory/${customerID}`}
                      className="text-gray-400 hover:text-gray-500"
                    >
                      <span>Back</span>
                    </a>
                  </div>
                </li>
              </ol>
            </nav>

            <div className="bg-white px-4 sm:px-6 lg:px-8 border border-gray-200 ml-10 mr-10 mt-10 mb-40">
              <form>
                <div className="space-y-12">
                  <div className="mt-10">
                    <div className="sm:flex sm:items-center">
                      <div className="sm:flex-auto">
                        <h1 className="text-2xl font-semibold leading-6 text-gray-900 mb-5">
                          Stock: {sumStock()}
                        </h1>
                      </div>

                      <div className="mt-4 sm:ml-4 sm:mt-0 sm:flex-none">
                        <button
                          type="button"
                          onClick={() => setOpen(true)}
                          className="block rounded-md bg-green-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                        >
                          Adjust Stock
                        </button>
                      </div>
                    </div>
                    <div className="mt-8 flow-root mb-10">
                      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                          <table className="min-w-full divide-y divide-gray-300">
                            <thead>
                              <tr>
                                <th
                                  scope="col"
                                  className="w-1/4 py-3.5 pl-4 pr-3 text-left text-sm font-medium text-gray-900 sm:pl-0"
                                >
                                  Date
                                </th>
                                <th
                                  scope="col"
                                  className="w-1/4 py-3.5 pl-4 pr-3 text-left text-sm font-medium text-gray-900 sm:pl-0"
                                >
                                  Type
                                </th>
                                <th
                                  scope="col"
                                  className="w-1/4 px-3 py-3.5 text-left text-sm font-medium text-gray-900"
                                >
                                  Quantity
                                </th>
                                <th
                                  scope="col"
                                  className="w-1/4 px-3 py-3.5 text-left text-sm font-medium text-gray-900"
                                >
                                  User
                                </th>

                                <th
                                  scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-medium text-gray-900"
                                ></th>
                              </tr>
                            </thead>

                            {stock?.orders?.length > 0
                              ? stock?.orders?.map((item) => (
                                  <tr key={item.inventoryID}>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-700 sm:pl-0">
                                      {dateFormatter(item.orderDate)}
                                    </td>
                                    <td className="text-sm font-medium text-gray-500">
                                      Order: {item.orderID}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                      {item.quantityOrdered}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"></td>
                                  </tr>
                                ))
                              : null}

                            {stock?.adjustments?.length > 0
                              ? stock?.adjustments?.map((item) => (
                                  <tr key={item.inventoryID}>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-700 sm:pl-0">
                                      {dateFormatter(item.receivingDate)}
                                    </td>
                                    <td className="text-sm font-medium text-gray-500">
                                      Adjustment
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                      {numberWithCommas(item.quantity)}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                      {item.userID
                                        ? item.firstName + " " + item.lastName
                                        : "System"}
                                    </td>
                                  </tr>
                                ))
                              : null}

                            {stock?.receiving?.length > 0
                              ? stock?.receiving?.map((item) => (
                                  <tr key={item.inventoryID}>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-700 sm:pl-0">
                                      {dateFormatter(item.receivingDate)}
                                    </td>
                                    <td className="text-sm font-medium text-gray-500">
                                      Received Shipment
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                      {numberWithCommas(item.quantity)}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                      {item.userID
                                        ? item.firstName + " " + item.lastName
                                        : "System"}
                                    </td>
                                  </tr>
                                ))
                              : null}

                            {stock?.receiving?.length == 0 &&
                            stock?.adjustments?.length == 0 &&
                            stock?.orders?.length == 0 ? (
                              <tbody className="divide-y divide-gray-200">
                                <tr>
                                  <td
                                    colSpan="4"
                                    className="w-full text-center whitespace-nowrap px-3 py-4 text-sm text-gray-500 col-span-6"
                                  >
                                    -- No stock movements found --
                                  </td>
                                </tr>
                              </tbody>
                            ) : null}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <StockAdd
          open={open}
          setOpen={setOpen}
          addedNotyf={addedNotyf}
          inventoryID={inventoryID}
          queryClient={queryClient}
        />
      </>
    );
  }
}

export default Stock;
