import Navbar from "../Components/Navbar";
import Topbar from "../Components/Topbar";
import {
  useLayoutEffect,
  useRef,
  useState,
  Fragment,
  React,
  useEffect,
} from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/20/solid";
import { sendGetRequest, useGetRequest, sendPostRequest } from "../Client";
import Swal from "sweetalert2";
import { Notyf } from "notyf";
import "notyf/notyf.min.css"; // for React, Vue and Svelte
import { dateFormatter } from "../config";
import { Link } from "react-router-dom";
import ChargeUpdate from "../Components/Billing/update";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ArrowDownIcon } from "@heroicons/react/24/outline";
import * as XLSX from "xlsx";

function Billing() {
  const initialDate = new Date().toISOString().split("T")[0];
  const initialDateArray = initialDate.split("-");

  const checkbox = useRef();
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedCharges, setSelectedCharges] = useState([]);
  const [searchCustomer, setSearchCustomer] = useState(0);

  const [open, setOpen] = useState(false);

  // updating
  const [editOpen, setEditOpen] = useState(false);
  const [chargeID, setChargeID] = useState(false);
  const queryClient = useQueryClient();

  const [updated, setUpdated] = useState(0);

  const [comments, setComments] = useState("");
  const [orderNumber, setOrderNumber] = useState("");
  const [receiverNumber, setReceiverNumber] = useState("");

  // updating

  const [billingList, setBillingList] = useState([]);

  const [picking, setPicking] = useState(false);

  // loading charges
  const { data: charges, isLoading } = useQuery({
    queryFn: () => sendGetRequest("billing/list", {}),
    queryKey: ["charges"],
  });

  // console.log('charges', charges)

  // customers dropdown
  const { status: statusCustomers, data: dataCustomers } = useGetRequest(
    "customers",
    {}
  );

  const { status: statusChargeTypes, data: dataChargeTypes } = useGetRequest(
    "charges",
    {}
  );

  const [inputs, setInputs] = useState({
    date:
      initialDateArray[0] +
      "-" +
      initialDateArray[1] +
      "-" +
      initialDateArray[2],
  });

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  // confirm delete
  const areYouSure = (id) => {
    Swal.fire({
      title: "Are you sure?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#de3333",
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("delete", id);
        billingDelete(id);
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  // confirmation notifications
  const notyf = new Notyf({
    position: {
      x: "right",
      y: "top",
    },
  });

  const updatedNotyf = () => {
    notyf.success("Charge updated");
  };

  const billingDelete = async (id) => {
    const res = await sendPostRequest("billing/delete", {
      customerChargeID: id,
    });

    setTimeout(() => {
      window.location.reload(false);
    }, 500);
  };

  const handleSubmit = async (event) => {
    const requestBody = {
      quantity: inputs.quantity,
      customerID: inputs.customerID,
      chargeID: inputs.chargeID,
      date: inputs.date,
      comments: comments,
      orderNumber: orderNumber,
      receiverNumber: receiverNumber,
    };

    setTimeout(() => {
      window.location.reload(false);
    }, 500);

    const res = await sendPostRequest("billing/add", requestBody);
  };

  const confirmCharges = async () => {
    const res = await sendPostRequest("billing/confirm", { selectedCharges });
    setBillingList(res.list);
  };

  function toggleAll() {
    setSelectedCharges(checked || indeterminate ? [] : billingList);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  }

  const sumChargeTotal = () => {
    let total = 0;

    billingList?.forEach((a) => {
      total += Number(a.amount);
    });

    return (Math.round(total * 100) / 100).toFixed(2);
  };

  const downloadCharges = async () => {
    const excelData = [];

    {
      billingList?.map((item) =>
        excelData.push({
          orderNumber: item.orderNumber,
          customer: item.customerName,
          chargeType: item.chargeName,
          quantity: item.quantity,
          cartonFee: item.processingCartonFee,
          cartonQuantity: item.processingCartonQuantity,
          chargeTotal: item.amount,
          date: item.chargeDate,
          calculation: item.calculation,
        })
      );
    }

    const worksheet = XLSX.utils.json_to_sheet(excelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "unbilled-charges.xlsx");
  };

  const [searchCartonFeeCheckbox, setSearchCartonFeeCheckbox] = useState(0);
  const [searchFromDate, setSearchFromDate] = useState("");
  const [searchToDate, setSearchToDate] = useState("");

  const fetchCustomerDashboard = async (searchCustomer) => {
    const requestBody = {
      customer: searchCustomer,
      fromDate: searchFromDate ? searchFromDate + " 00:00:00" : null,
      toDate: searchToDate ? searchToDate + " 23:59:59" : null,
      cartonFee: searchCartonFeeCheckbox,
    };

    const res = await sendPostRequest("billing/list", requestBody);

    setBillingList(res.charges);
  };

  useEffect(() => {
    fetchCustomerDashboard(searchCustomer);
  }, [searchCustomer, searchCartonFeeCheckbox, searchFromDate, searchToDate]);

  useEffect(() => {
    setBillingList(charges?.list);
  }, [charges]);

  const [isHovered, setIsHovered] = useState(null);
  const handleMouseOver = (e) => {
    setIsHovered(e.target.id);
  };

  if (
    // status == "fetched" &&
    !isLoading &&
    statusCustomers == "fetched" &&
    statusChargeTypes == "fetched"
  ) {
    return (
      <>
        <div className="flex flex-row justify-start w-full">
          <Navbar />

          <div className="flex flex-col w-4/5 bg-gray-100 ml-80">
            <Topbar />
            {/* content */}

            {billingList?.length > 0 ? (
              <div className="bg-white px-4 sm:px-6 lg:px-8 border border-gray-200 ml-10 mr-10 mt-10 mb-64">
                <div className="sm:flex sm:items-center ">
                  <div className="sm:flex-auto">
                    <h1 className="text-base font-semibold leading-6 text-gray-900 mt-10">
                      New Charges
                    </h1>
                    <p className="mt-2 text-sm text-gray-700">
                      {billingList?.length} new charge
                      {billingList?.length === 1 ? "" : "s"} to be processed
                    </p>
                    <div className="mt-2 text-sm text-gray-700">
                      Total : ${sumChargeTotal()}
                    </div>
                  </div>

                  <div
                    className="flex flex-row hover:cursor-pointer"
                    onClick={() => {
                      downloadCharges();
                    }}
                  >
                    {" "}
                    <div className="text-sm text-gray-700 mr-2 underline hover:text-indigo-700 hover:cursor-pointer">
                      Download Charges
                    </div>
                    <ArrowDownIcon
                      className="h-5 w-5 mr-10"
                      aria-hidden="true"
                    />
                  </div>

                  <div className="text-sm text-gray-700 mr-12 underline hover:text-indigo-700 hover:cursor-pointer">
                    <Link to={`/billed`}>View billed charges</Link>
                  </div>

                  <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                    {selectedCharges?.length > 0 && (
                      <button
                        type="button"
                        onClick={() => {
                          setPicking(true);
                        }}
                        className="block rounded-md bg-indigo-600 px-3 py-1.5 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        Invoice Charges
                      </button>
                    )}
                    {selectedCharges?.length == 0 && (
                      <button
                        type="button"
                        className="block rounded-md bg-slate-100 px-3 py-1.5 text-center text-sm font-semibold leading-6 text-slate-300 shadow-sm "
                      >
                        Select Charges
                      </button>
                    )}
                  </div>

                  <div className="ml-4 grid justify-items-end">
                    <button
                      type="submit"
                      onClick={
                        () => setOpen(true)
                        // pickingslip()
                      }
                      className="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Add Charge
                    </button>
                  </div>
                </div>

                <form onSubmit={() => fetchCustomerDashboard}>
                  <div className="border px-2 py-2 mt-5">
                    <div className="w-full flex flex-row mt-4 justify-center align-end">
                      <div className="w-1/5">
                        <div className="mb-5 mx-2">
                          <label
                            htmlFor="customer"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Customer
                          </label>
                          <div className="mt-2">
                            <select
                              id="customer"
                              name="customer"
                              onChange={(e) =>
                                setSearchCustomer(e.target.value)
                              }
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                            >
                              <option></option>
                              {dataCustomers.customers.map((customer) => (
                                <option value={customer.customerID}>
                                  {customer.customerName}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="w-1/5">
                        <div className="mb-5 mx-2">
                          <div className="sm:col-span-2">
                            <label
                              htmlFor="fromDate"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              From Date
                            </label>
                            <div className="mt-2">
                              <input
                                type="date"
                                name="fromDate"
                                id="fromDate"
                                onChange={(e) =>
                                  setSearchFromDate(e.target.value)
                                }
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-1/5">
                        <div className="sm:col-span-2">
                          <label
                            htmlFor="toDate"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            To Date
                          </label>
                          <div className="mt-2">
                            <input
                              type="date"
                              name="toDate"
                              id="toDate"
                              onChange={(e) => setSearchToDate(e.target.value)}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="w-1/5">
                        {" "}
                        <div className="mb-5 mx-2">
                          <label
                            htmlFor="cartonFee"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Carton Fee
                          </label>
                          <div className="mt-2">
                            <input
                              type="checkbox"
                              className="ml-4 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                              onChange={() =>
                                setSearchCartonFeeCheckbox(
                                  !searchCartonFeeCheckbox
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>

                <div className="mt-8 flow-root">
                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                      <div className="relative">
                        <table className="min-w-full table-fixed divide-y divide-gray-300">
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                className="w-1/7  px-7 sm:w-12 sm:px-6 relative"
                              >
                                <input
                                  type="checkbox"
                                  className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                  ref={checkbox}
                                  checked={checked}
                                  onChange={toggleAll}
                                />
                              </th>

                              <th
                                scope="col"
                                className="w-1/8 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Date
                              </th>
                              <th
                                scope="col"
                                className="w-1/7 py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                              >
                                Customer
                              </th>
                              <th
                                scope="col"
                                className="w-1/7 py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                              >
                                Order/Receiver #
                              </th>

                              <th
                                scope="col"
                                className="w-1/7 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Charge Type
                              </th>
                              <th
                                scope="col"
                                className="w-1/7 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Quantity
                              </th>
                              <th
                                scope="col"
                                className="w-1/7 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Weight
                              </th>
                              <th
                                scope="col"
                                className="w-1/7 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Count
                              </th>
                              <th
                                scope="col"
                                className="w-1/7 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Carton Fee
                              </th>

                              <th
                                scope="col"
                                className="w-1/7 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Total Order Processing
                              </th>
                              <th
                                scope="col"
                                className="w-1/7 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Shipping Fee
                              </th>

                              <th
                                scope="col"
                                className="w-1/7 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Charge Total
                              </th>

                              <th
                                scope="col"
                                className="w-1/7 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              ></th>
                            </tr>
                          </thead>

                          <tbody className="divide-y divide-gray-200 bg-white">
                            {billingList.map((list) => (
                              <tr
                                key={list.customerChargeID + Math.random()}
                                className={
                                  selectedCharges.includes(list)
                                    ? "bg-gray-50"
                                    : undefined
                                }
                              >
                                <td className="relative px-7 sm:w-12 sm:px-6">
                                  {selectedCharges.includes(list) && (
                                    <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                                  )}
                                  <input
                                    type="checkbox"
                                    className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                    value={list.email}
                                    checked={selectedCharges.includes(list)}
                                    onChange={(e) =>
                                      setSelectedCharges(
                                        e.target.checked
                                          ? [...selectedCharges, list]
                                          : selectedCharges.filter(
                                              (p) => p !== list
                                            )
                                      )
                                    }
                                  />
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {dateFormatter(list.chargeDate)}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hover:text-indigo-900 underline hover:cursor-pointer">
                                  {list.customerName}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hover:text-indigo-900">
                                  {list.chargeName === "Receiving charge"
                                    ? list.stockMovementID
                                    : list.orderNumber
                                    ? list.orderNumber
                                    : null}
                                </td>

                                <td
                                  className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hover: cursor-pointer"
                                  id={list.calculation}
                                  onMouseOver={handleMouseOver}
                                  onMouseOut={() => {
                                    setIsHovered(null);
                                  }}
                                >
                                  {list.chargeName}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {list.quantity}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {list.processingWeight}
                                </td>
                                <td className="whitespace-wrap px-3 py-4 text-xs text-gray-500">
                                  {list.chargeName === "Processing charge"
                                    ? list.processingCount
                                    : null}
                                </td>
                                <td className="whitespace-wrap px-3 py-4 text-xs text-gray-500">
                                  {list.chargeName === "Processing charge"
                                    ? list.processingCartonFee
                                    : ""}
                                </td>

                                <td className="whitespace-wrap px-3 py-4 text-xs text-gray-500">
                                  {list.chargeName === "Processing charge"
                                    ? Math.round(
                                        (list.amount * 100) / 100 -
                                          list.processingShippingFee
                                      ).toFixed(2)
                                    : ""}
                                </td>

                                <td className="whitespace-wrap px-3 py-4 text-xs text-gray-500">
                                  {list.chargeName === "Processing charge"
                                    ? list.processingShippingFee
                                    : null}
                                </td>

                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  $
                                  {(
                                    Math.round(list.amount * 100) / 100
                                  ).toFixed(2)}
                                </td>

                                <td className="block text-right">
                                  <span className="mt-2 isolate inline-flex rounded-md shadow-sm">
                                    <button
                                      type="button"
                                      onClick={() => {
                                        setChargeID(list.customerChargeID);
                                        setEditOpen(true);
                                      }}
                                      className="relative inline-flex items-center rounded-l-md bg-amber-500 hover:bg-amber-400 focus-visible:outline-amber-600 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 focus:z-10"
                                    >
                                      Edit
                                    </button>
                                  </span>

                                  <span className="mt-2 isolate inline-flex rounded-md shadow-sm">
                                    <button
                                      type="button"
                                      onClick={() =>
                                        areYouSure(list.customerChargeID)
                                      }
                                      className="relative -ml-px inline-flex items-center rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 hover:bg-red-400 focus:z-10"
                                    >
                                      Delete
                                    </button>
                                  </span>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="bg-white px-4 sm:px-6 lg:px-8 border border-gray-200 ml-10 mr-10 mt-10 mb-64">
                <div className="sm:flex sm:items-center">
                  <div className="sm:flex-auto">
                    <h1 className="text-base font-semibold leading-6 text-gray-900 mt-10">
                      New Charges
                    </h1>
                    <p className="mt-2 mb-10 text-sm text-gray-700">
                      0 charges found
                    </p>
                  </div>

                  <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                    {selectedCharges.length > 0 && (
                      <button
                        type="button"
                        onClick={
                          () => setPicking(true)
                          // pickingslip()
                        }
                        className="block rounded-md bg-indigo-600 px-3 py-1.5 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        Invoice Charges
                      </button>
                    )}
                    {selectedCharges.length == 0 && (
                      <button
                        type="button"
                        className="block rounded-md bg-slate-100 px-3 py-1.5 text-center text-sm font-semibold leading-6 text-slate-300 shadow-sm "
                      >
                        Select Charges
                      </button>
                    )}
                  </div>

                  <div className="ml-4 grid justify-items-end">
                    <button
                      type="submit"
                      onClick={
                        () => setOpen(true)
                        // pickingslip()
                      }
                      className="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Add Charge
                    </button>
                  </div>
                </div>

                <form onSubmit={() => fetchCustomerDashboard}>
                  <div className="border px-2 py-2 mt-5">
                    <div className="w-full flex flex-row mt-4 justify-center align-end">
                      <div className="w-1/5">
                        <div className="mb-5 mx-2">
                          <label
                            htmlFor="customer"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Customer
                          </label>
                          <div className="mt-2">
                            <select
                              id="customer"
                              name="customer"
                              onChange={(e) =>
                                setSearchCustomer(e.target.value)
                              }
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                            >
                              <option></option>
                              {dataCustomers.customers.map((customer) => (
                                <option value={customer.customerID}>
                                  {customer.customerName}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="w-1/5">
                        <div className="mb-5 mx-2">
                          <div className="sm:col-span-2">
                            <label
                              htmlFor="fromDate"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              From Date
                            </label>
                            <div className="mt-2">
                              <input
                                type="date"
                                name="fromDate"
                                id="fromDate"
                                onChange={(e) =>
                                  setSearchFromDate(e.target.value)
                                }
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-1/5">
                        <div className="sm:col-span-2">
                          <label
                            htmlFor="toDate"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            To Date
                          </label>
                          <div className="mt-2">
                            <input
                              type="date"
                              name="toDate"
                              id="toDate"
                              onChange={(e) => setSearchToDate(e.target.value)}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="w-1/5">
                        {" "}
                        <div className="mb-5 mx-2">
                          <label
                            htmlFor="cartonFee"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Carton Fee
                          </label>
                          <div className="mt-2">
                            <input
                              type="checkbox"
                              className="ml-4 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                              onChange={() =>
                                setSearchCartonFeeCheckbox(
                                  !searchCartonFeeCheckbox
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>

        {isHovered && (
          <div>
            <div class="fixed top-80 left-80 w-[79%] bg-slate-100 border-4 p-4 flex justify-center">
              <h2 className="text-black">{isHovered ? isHovered : ""}</h2>
            </div>
          </div>
        )}

        <Transition.Root show={open} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={setOpen}>
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                      <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                        <div className="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
                          <div className="flex items-center justify-between">
                            <Dialog.Title className="text-lg font-medium text-gray-900"></Dialog.Title>
                            <div className="ml-3 flex h-7 items-center">
                              <button
                                type="button"
                                className="-m-2 p-2 text-gray-400 hover:text-gray-500"
                                onClick={() => setOpen(false)}
                              ></button>
                            </div>
                          </div>
                          <table className="min-w-full divide-y divide-gray-300 mt-10">
                            <tbody className="divide-gray-200">
                              <tr>
                                <td>
                                  {" "}
                                  <label
                                    htmlFor="productCode"
                                    className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                                  >
                                    Customer
                                  </label>
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  <select
                                    id="customerID"
                                    name="customerID"
                                    required="required"
                                    onChange={handleChange}
                                    value={inputs.customerID || ""}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                  >
                                    <option></option>
                                    {dataCustomers.customers.map((customer) => (
                                      <option value={customer.customerID}>
                                        {customer.customerName}
                                      </option>
                                    ))}
                                  </select>
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  {" "}
                                  <label
                                    htmlFor="productDescription"
                                    className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                                  >
                                    Charge Types
                                  </label>
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  <select
                                    id="chargeID"
                                    name="chargeID"
                                    required="required"
                                    onChange={handleChange}
                                    value={inputs.chargeID || ""}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                  >
                                    <option></option>
                                    {dataChargeTypes.charges.map((charge) => (
                                      <option value={charge.chargeTypeID}>
                                        {charge.chargeName}
                                      </option>
                                    ))}
                                  </select>
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  {" "}
                                  <label
                                    htmlFor="productCategory"
                                    className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                                  >
                                    Quantity
                                  </label>
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  <select
                                    id="quantity"
                                    name="quantity"
                                    required="required"
                                    onChange={handleChange}
                                    value={inputs.quantity || ""}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                  >
                                    <option></option>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                    <option>6</option>
                                    <option>7</option>
                                    <option>8</option>
                                    <option>9</option>
                                    <option>10</option>
                                  </select>
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  {" "}
                                  <label
                                    htmlFor="unitPrice"
                                    className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                                  >
                                    Date
                                  </label>
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  <input
                                    type="date"
                                    name="date"
                                    id="date"
                                    required="required"
                                    onChange={handleChange}
                                    value={inputs.date || ""}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  {" "}
                                  <label
                                    htmlFor="comments"
                                    className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                                  >
                                    Comments
                                  </label>
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  <input
                                    type="text"
                                    name="comments"
                                    id="comments"
                                    onChange={(e) => {
                                      setComments(e.target.value);
                                    }}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  {" "}
                                  <label
                                    htmlFor="orderNumber"
                                    className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                                  >
                                    Order Number
                                  </label>
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  <input
                                    type="text"
                                    name="orderNumber"
                                    id="orderNumber"
                                    onChange={(e) => {
                                      setOrderNumber(e.target.value);
                                    }}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  {" "}
                                  <label
                                    htmlFor="receiverNumber"
                                    className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                                  >
                                    Receiver Number
                                  </label>
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  <input
                                    type="text"
                                    name="receiverNumber"
                                    id="receiverNumber"
                                    onChange={(e) => {
                                      setReceiverNumber(e.target.value);
                                    }}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
                          <div className="mt-6">
                            <a
                              href="#"
                              onClick={async () => {
                                await handleSubmit();
                                setOpen(false);
                              }}
                              className="flex items-center justify-center rounded-md border border-transparent bg-green-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-green-700"
                            >
                              Add Charge
                            </a>
                          </div>
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <Transition.Root show={picking} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={setPicking}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                    <div>
                      <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                        <CheckIcon
                          className="h-6 w-6 text-green-600"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="mt-3 text-center sm:mt-5">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Confirm charges
                        </Dialog.Title>
                        <div className="mt-4 text-sm font-semibold text-gray-500">
                          Are you sure you would like to invoice these charges?
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-6 flex flex-row justify-between">
                      <div>
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                          onClick={() => setPicking(false)}
                        >
                          Cancel
                        </button>
                      </div>
                      <div>
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                          onClick={() => {
                            setPicking(false);
                            confirmCharges();
                          }}
                        >
                          Confirm
                        </button>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {chargeID && (
          <ChargeUpdate
            editOpen={editOpen}
            setEditOpen={setEditOpen}
            updatedNotyf={updatedNotyf}
            chargeID={chargeID}
            queryClient={queryClient}
          />
        )}
      </>
    );
  }
}

export default Billing;
