import { React, Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  sendPostRequest,
  useGetRequest,
  sendPostRequestMultipart,
} from "../../Client";
import { useMutation } from "@tanstack/react-query";

export default function CustomerInventoryAdd(props) {
  let customerID = props.customerID;

  // adding new
  const [productCodeNew, setProductCodeNew] = useState();
  const [productDescriptionNew, setProductDescriptionNew] = useState();
  const [productUnitPriceNew, setProductUnitPriceNew] = useState();
  const [productImage, setProductImage] = useState();
  const [productImage2, setProductImage2] = useState();
  const [adminOnly, setAdminOnly] = useState(false);
  const [min, setMin] = useState();
  const [max, setMax] = useState();
  const [lowPoint, setLowPoint] = useState();
  const [typePiece, setTypePiece] = useState();
  const [offWeb, setOffWeb] = useState();
  const [unitCount, setUnitCount] = useState();
  const [price2, setPrice2] = useState();
  const [productStatus, setProductStatus] = useState();
  const [productDivision, setProductDivision] = useState(1);
  const [productCategory1, setProductCategory1] = useState();
  const [productCategory2, setProductCategory2] = useState();
  const [productCategory3, setProductCategory3] = useState();
  // const [defaultLocation, setDefaultLocation] = useState();

  const [locations, setLocations] = useState([]);

  function addRemoveLocation(value) {
    // const locationValue = Number(value);
    const locationValue = value;
    if (locationValue) {
      if (locations.includes(value)) {
        const locationWithoutThisValue = locations.filter((x) => x != value);
        setLocations(locationWithoutThisValue);
      } else {
        setLocations([...locations, value]);
      }
    }
  }

  const { status: statusLocations, data: dataLocations } = useGetRequest(
    "locations",
    {}
  );

  const { status: statusDropdownCategories1, data: dataDropdownCategories1 } =
    useGetRequest(
      "inventory/customer/categories/1/" + customerID + "/" + productDivision,
      {}
    );

  const { status: statusDropdownCategories2, data: dataDropdownCategories2 } =
    useGetRequest(
      "inventory/customer/categories/2/" + customerID + "/" + productDivision,
      {}
    );

  const { status: statusDropdownCategories3, data: dataDropdownCategories3 } =
    useGetRequest(
      "inventory/customer/categories/3/" + customerID + "/" + productDivision,
      {}
    );

  // adding inventory
  const inventoryAdd = async (path, body) => {
    const res = await sendPostRequestMultipart(path, body);
    props.addedNotyf();
  };

  const { mutateAsync: doAddMutation } = useMutation({
    mutationFn: (data) => {
      return inventoryAdd(data.path, { customerID, ...data.body });
    },
    onSuccess: () => {
      props.queryClient.invalidateQueries({ queryKey: ["inventory"] });
    },
  });

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={props.setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="text-lg font-medium text-gray-900"></Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="-m-2 p-2 text-gray-400 hover:text-gray-500"
                            onClick={() => props.setOpen(false)}
                          ></button>
                        </div>
                      </div>
                      <table className="min-w-full divide-y divide-gray-300 mt-10">
                        <tbody className="divide-gray-200">
                          <tr>
                            <td>
                              {" "}
                              <label
                                htmlFor="productCode"
                                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                              >
                                Product Code
                              </label>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <input
                                type="text"
                                name="productCode"
                                id="productCode"
                                onChange={(e) => {
                                  setProductCodeNew(e.target.value);
                                }}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <label
                                htmlFor="productDescription"
                                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                              >
                                Product Description
                              </label>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <input
                                type="text"
                                name="productDescription"
                                id="productDescription"
                                onChange={(e) => {
                                  setProductDescriptionNew(e.target.value);
                                }}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>
                              {" "}
                              <label
                                htmlFor="productDivision"
                                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                              >
                                Division
                              </label>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <select
                                id="productDivision"
                                name="productDivision"
                                onChange={(e) =>
                                  setProductDivision(e.target.value)
                                }
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                              >
                                <option></option>
                                {props.dataDropdownDivisions?.divisions?.map(
                                  (division) => (
                                    <option
                                      key={division.divisionID}
                                      value={division.divisionID}
                                    >
                                      {division.divisionName}
                                    </option>
                                  )
                                )}
                              </select>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              {" "}
                              <label
                                htmlFor="productCategory1"
                                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                              >
                                Category 1
                              </label>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <select
                                id="productCategory1"
                                name="productCategory1"
                                onChange={(e) =>
                                  setProductCategory1(e.target.value)
                                }
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                              >
                                <option></option>
                                {dataDropdownCategories1?.categories?.map(
                                  (category) => (
                                    <option
                                      key={category.categoryID}
                                      value={category.categoryID}
                                    >
                                      {category.categoryName}
                                    </option>
                                  )
                                )}
                              </select>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              {" "}
                              <label
                                htmlFor="productCategory2"
                                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                              >
                                Category 2
                              </label>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <select
                                id="productCategory2"
                                name="productCategory2"
                                onChange={(e) =>
                                  setProductCategory2(e.target.value)
                                }
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                              >
                                <option></option>
                                {dataDropdownCategories2?.categories?.map(
                                  (category) => (
                                    <option
                                      key={category.categoryID}
                                      value={category.categoryID}
                                    >
                                      {category.categoryName}
                                    </option>
                                  )
                                )}
                              </select>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              {" "}
                              <label
                                htmlFor="productCategory3"
                                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                              >
                                Category 3
                              </label>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <select
                                id="productCategory3"
                                name="productCategory3"
                                onChange={(e) =>
                                  setProductCategory3(e.target.value)
                                }
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                              >
                                <option></option>
                                {dataDropdownCategories3?.categories?.map(
                                  (category) => (
                                    <option
                                      key={category.categoryID}
                                      value={category.categoryID}
                                    >
                                      {category.categoryName}
                                    </option>
                                  )
                                )}
                              </select>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              {" "}
                              <label
                                htmlFor="unitPrice"
                                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                              >
                                Price
                              </label>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <input
                                type="text"
                                name="unitPrice"
                                id="unitPrice"
                                onChange={(e) => {
                                  setProductUnitPriceNew(e.target.value);
                                }}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>
                              {" "}
                              <label
                                htmlFor="adminOnly"
                                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                              >
                                Admin Only
                              </label>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <input
                                type="checkbox"
                                name="adminOnly"
                                id="adminOnly"
                                onChange={(e) => {
                                  setAdminOnly(!adminOnly);
                                }}
                                className="block w-4 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>
                              {" "}
                              <label
                                htmlFor="min"
                                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                              >
                                Min
                              </label>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <input
                                type="text"
                                name="min"
                                id="min"
                                onChange={(e) => {
                                  setMin(e.target.value);
                                }}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>
                              {" "}
                              <label
                                htmlFor="max"
                                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                              >
                                Max
                              </label>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <input
                                type="text"
                                name="max"
                                id="max"
                                onChange={(e) => {
                                  setMax(e.target.value);
                                }}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>
                              {" "}
                              <label
                                htmlFor="lowPoint"
                                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                              >
                                Low Point
                              </label>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <input
                                type="text"
                                name="lowPoint"
                                id="lowPoint"
                                onChange={(e) => {
                                  setLowPoint(e.target.value);
                                }}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>
                              {" "}
                              <label
                                htmlFor="typePiece"
                                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                              >
                                Type Piece
                              </label>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <input
                                type="text"
                                name="typePiece"
                                id="typePiece"
                                onChange={(e) => {
                                  setTypePiece(e.target.value);
                                }}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>
                              {" "}
                              <label
                                htmlFor="offWeb"
                                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                              >
                                Off Web
                              </label>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <input
                                type="text"
                                name="offWeb"
                                id="offWeb"
                                onChange={(e) => {
                                  setOffWeb(e.target.value);
                                }}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>
                              {" "}
                              <label
                                htmlFor="unitCount"
                                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                              >
                                Unit Count
                              </label>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <input
                                type="text"
                                name="unitCount"
                                id="unitCount"
                                onChange={(e) => {
                                  setUnitCount(e.target.value);
                                }}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>
                              {" "}
                              <label
                                htmlFor="price2"
                                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                              >
                                Price 2
                              </label>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <input
                                type="text"
                                name="price2"
                                id="price2"
                                onChange={(e) => {
                                  setPrice2(e.target.value);
                                }}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>
                              {" "}
                              <label
                                htmlFor="productStatus"
                                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                              >
                                Status
                              </label>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <input
                                type="text"
                                name="productStatus"
                                id="productStatus"
                                onChange={(e) => {
                                  setProductStatus(e.target.value);
                                }}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </td>
                          </tr>

                          {dataLocations.status !== "success" ? null : (
                            <>
                              <tr>
                                <td>
                                  {" "}
                                  <label
                                    htmlFor="region"
                                    className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                                  >
                                    Locations
                                  </label>
                                </td>

                                <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                                  <label className="relative">
                                    <input
                                      type="checkbox"
                                      className="hidden peer"
                                    />
                                    {"Locations (+/-)"}

                                    <div className="absolute bg-white border transition-opacity opacity-0 pointer-events-none peer-checked:opacity-100 peer-checked:pointer-events-auto">
                                      <ul className="grid grid-cols-3 w-64">
                                        {dataLocations.locations.map(
                                          (option, i) => {
                                            return (
                                              <li
                                                className="mr-15"
                                                key={option}
                                              >
                                                <label className="flex whitespace-nowrap cursor-pointer px-1 py-1 transition-colors hover:bg-blue-100 [&:has(input:checked)]:bg-blue-200">
                                                  <input
                                                    type="checkbox"
                                                    name="locations"
                                                    value={option.name}
                                                    onChange={() => {
                                                      addRemoveLocation(
                                                        option.name
                                                      );
                                                    }}
                                                    className="cursor-pointer"
                                                  />
                                                  <span className="ml-1 text-xs font-medium">
                                                    {option.name}
                                                  </span>
                                                </label>
                                              </li>
                                            );
                                          }
                                        )}
                                      </ul>
                                    </div>
                                  </label>
                                </td>
                              </tr>
                            </>
                          )}

                          <tr>
                            <td></td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <input
                                type="text"
                                name="defaultLocation"
                                id="defaultLocation"
                                value={locations}
                                disabled
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>
                              {" "}
                              <label
                                htmlFor="image"
                                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                              >
                                Image
                              </label>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <input
                                type="file"
                                name="image"
                                id="image"
                                onChange={(event) => {
                                  setProductImage(event.target.files[0]);
                                }}
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>
                              {" "}
                              <label
                                htmlFor="image2"
                                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                              >
                                Image 2
                              </label>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <input
                                type="file"
                                name="image2"
                                id="image2"
                                onChange={(event) => {
                                  setProductImage2(event.target.files[0]);
                                }}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
                      <div className="mt-6">
                        <a
                          href="#"
                          onClick={async () => {
                            try {
                              if (!productCategory1) {
                                alert("Please select a category 1");
                                return;
                              }

                              await doAddMutation({
                                path: "inventory/add",
                                body: {
                                  productCode: productCodeNew,
                                  description: productDescriptionNew,
                                  unitPrice: productUnitPriceNew,
                                  adminOnly: adminOnly ? 1 : 0,
                                  image: productImage,
                                  image2: productImage2,
                                  division: productDivision,
                                  category1: productCategory1,
                                  category2: productCategory2,
                                  category3: productCategory3,
                                  min: min,
                                  max: max,
                                  lowPoint: lowPoint,
                                  typePiece: typePiece,
                                  offWeb: offWeb,
                                  unitCount: unitCount,
                                  price2: price2,
                                  productStatus: productStatus,
                                  defaultLocation: locations,
                                },
                              });
                              props.setOpen(false);
                              setAdminOnly(false);
                            } catch (e) {
                              console.log(e);
                            }
                          }}
                          className="flex items-center justify-center rounded-md border border-transparent bg-green-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-green-700"
                        >
                          Add Item
                        </a>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
