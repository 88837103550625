import { React } from "react";

export default function ShelfRow(props) {
  return (
    <>
      <tr className="border-t-2 border-gray-200">
        <td>
          {" "}
          <label
            htmlFor="numberCartons"
            className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
          >
            Number of cartons
          </label>
        </td>

        <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
          <input
            type="number"
            name="numberCartons"
            onChange={(e) =>
                props.setShelfData({
                  ...props.shelfData,
                  [props.id + "-numberCartons"]: e.target.value,
                })
              }
            // value={inputs.numberCartons || ""}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </td>
      </tr>

      <tr className="border-b-2">
        <td>
          {" "}
          <label
            htmlFor="unitsPerCarton"
            className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
          >
            Units per carton
          </label>
        </td>
        <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
          <input
            type="number"
            name="unitsPerCarton"
            onChange={(e) =>
                props.setShelfData({
                  ...props.shelfData,
                  [props.id + "-unitsPerCarton"]: e.target.value,
                })
              }
            required="required"
            // value={inputs.unitsPerCarton || ""}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </td>
      </tr>
    </>
  );
}
