import { React } from "react";
import Navbar from "../../Components/Navbar";
import Topbar from "../../Components/Topbar";
import Success from "../../Components/Success";
import { useLayoutEffect, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { sendPostRequest, useGetRequest } from "../../Client";
import { Formik, Form, Field, ErrorMessage, useFormik } from "formik";
import { Notyf } from "notyf";
import "notyf/notyf.min.css"; // for React, Vue and Svelte
import Swal from "sweetalert2";
import CustomersBreadcrumb from "../../Components/CustomersBreadcrumb";
import CustomerName from "../../Components/CustomerName";

function CustomerEdit() {
  const { customerID } = useParams();

  const [customerName, setCustomerName] = useState("");
  const [contactName, setContactName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  // const [country, setCountry] = useState();

  const { status, data } = useGetRequest("customer/" + customerID, {});

  useEffect(() => {
    setCustomerName(data?.customer?.customerName);
    setContactName(data?.customer?.contactName);
    setContactEmail(data?.customer?.contactEmail);
    setContactNumber(data?.customer?.contactNumber);
    setAddress1(data?.customer?.address1);
    setAddress2(data?.customer?.address2);
    setCity(data?.customer?.city);
    setState(data?.customer?.state);
    setZip(data?.customer?.zip);
  }, [data]);

  const navigate = useNavigate();

  // confirmation notifications
  const notyf = new Notyf({
    position: {
      x: "right",
      y: "top",
    },
  });

  const updatedNotyf = () => {
    notyf.success("Inventory updated");
  };

  // confirm delete
  const areYouSure = () => {
    Swal.fire({
      title: "Are you sure?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#de3333",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(customerID);
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  function handleDelete(id) {
    const res = sendPostRequest("customer/delete", {
      customerID: id,
    });
    
    navigate(`/customers`, { replace: true, state: { deleted: 1 } });
  }

  const handleSubmit = () => {
    const requestBody = {
      customerName: customerName,
      contactName: contactName,
      contactEmail: contactEmail,
      contactNumber: contactNumber,
      address1: address1,
      address2: address2,
      city: city,
      state: state,
      zip: zip,
    };

    const res = sendPostRequest("customer/update/" + customerID, requestBody);

    updatedNotyf();

    navigate(`/customers`, { replace: true, state: { added: 1 } });
  };

  if (status == "fetched") {
    return (
      <div className="flex flex-row w-full">
        <Navbar />

        <div className="flex flex-col w-4/5 bg-gray-100 ml-80">
          <Topbar />

          <CustomersBreadcrumb />

          <CustomerName customerName={data?.customer?.customerName} />


          <div className="bg-white px-4 sm:px-6 lg:px-8 border border-gray-200 ml-10 mr-10 mt-10 mb-40">
            <form>
              <div className="space-y-12">
                <div className="pb-12 mt-8 mb-24">
                  <h2 className="text-2xl font-semibold leading-7 text-gray-900">
                    Edit Customer Information
                  </h2>

                  <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div className="col-span-2">
                      <label
                        htmlFor="customerName"
                        id="customerName"
                        name="customerName"
                        className="block text-sm font-medium text-gray-900"
                      >
                        Customer Name
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="customerName"
                          // onChange={handleChange("customerName")}
                          onChange={(e) => {
                            setCustomerName(e.target.value);
                          }}
                          value={customerName ? customerName : ""}
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="col-span-2">
                      <label
                        htmlFor="contactName"
                        id="contactName"
                        className="block text-sm font-medium text-gray-900"
                      >
                        Contact Name
                      </label>
                      <div className="mt-2">
                        <input
                          name="contactName"
                          type="text"
                          // onChange={handleChange("contactName")}
                          onChange={(e) => {
                            setContactName(e.target.value);
                          }}
                          value={contactName ? contactName : ""}
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="col-span-2">
                      <label
                        htmlFor="contactEmail"
                        id="contactEmail"
                        className="block text-sm font-medium text-gray-900"
                      >
                        Contact Email Address
                      </label>
                      <div className="mt-2">
                        <input
                          name="contactEmail"
                          type="text"
                          // onChange={handleChange("contactEmail")}
                          onChange={(e) => {
                            setContactEmail(e.target.value);
                          }}
                          value={contactEmail ? contactEmail : ""}
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="col-span-2">
                      <label
                        htmlFor="contactNumber"
                        id="contactNumber"
                        className="block text-sm font-medium text-gray-900"
                      >
                        Contact Number
                      </label>
                      <div className="mt-2">
                        <input
                          name="contactNumber"
                          type="text"
                          // onChange={handleChange("contactNumber")}
                          onChange={(e) => {
                            setContactNumber(e.target.value);
                          }}
                          value={contactNumber ? contactNumber : ""}
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="col-span-2">
                      <label
                        htmlFor="address1"
                        id="address1"
                        className="block text-sm font-medium text-gray-900"
                      >
                        Address Line 1
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="address1"
                          // onChange={handleChange("address1")}
                          onChange={(e) => {
                            setAddress1(e.target.value);
                          }}
                          value={address1 ? address1 : ""}
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="col-span-2">
                      <label
                        htmlFor="address2"
                        id="address2"
                        className="block text-sm font-medium text-gray-900"
                      >
                        Address Line 2
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="address2"
                          // onChange={handleChange("address2")}
                          onChange={(e) => {
                            setAddress2(e.target.value);
                          }}
                          value={address2 ? address2 : ""}
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="col-span-2">
                      <label
                        htmlFor="city"
                        id="city"
                        className="block text-sm font-medium text-gray-900"
                      >
                        City
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="city"
                          // onChange={handleChange("city")}
                          onChange={(e) => {
                            setCity(e.target.value);
                          }}
                          value={city ? city : ""}
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="col-span-2">
                      <label
                        htmlFor="state"
                        id="state"
                        className="block text-sm font-medium text-gray-900"
                      >
                        State
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="state"
                          // onChange={handleChange("state")}
                          onChange={(e) => {
                            setState(e.target.value);
                          }}
                          value={state ? state : ""}
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="col-span-2">
                      <label
                        htmlFor="zip"
                        id="zip"
                        className="block text-sm font-medium text-gray-900"
                      >
                        ZIP
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="zip"
                          // onChange={handleChange("zip")}
                          onChange={(e) => {
                            setZip(e.target.value);
                          }}
                          value={zip ? zip : ""}
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div className="flex items-center justify-end gap-x-6 mb-5">
                <button
                  type="button"
                  onClick={() => areYouSure()}
                  className="text-sm font-semibold leading-6 text-red-500"
                >
                  Delete
                </button>

                <button
                  type="submit"
                  className="flex items-center justify-center rounded-md border border-transparent bg-amber-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-amber-700"
                  onClick={() => handleSubmit()}
                >
                  Edit
                </button>
              </div>
            </form>
            {/* )}
            </Formik> */}
          </div>
        </div>
      </div>
    );
  }
}

export default CustomerEdit;
