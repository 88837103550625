import { React, Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { sendPostRequest, useGetRequest } from "../../Client";
import { useMutation } from "@tanstack/react-query";
import ShelfRow from "./ShelfRow";
import SkidRow from "./SkidRow";
import { PlusIcon, MinusIcon } from "@heroicons/react/20/solid";

export default function ReceiverUpdate(props) {
  let stockMovementID = props.stockMovementID;

  const [shelf, setShelf] = useState(1);
  const [shelfs, setShelfs] = useState(1);

  const [receivingDate, setReceivingDate] = useState();
  const [carrierName, setCarrierName] = useState();
  const [vendorName, setVendorName] = useState();
  const [vendorAddress, setVendorAddress] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [phone, setPhone] = useState();
  const [zip, setZip] = useState();
  const [shelfData, setShelfData] = useState([]);
  const [customerID, setCustomerID] = useState();
  const [customerName, setCustomerName] = useState();
  const [inventoryID, setInventoryID] = useState();
  const [inventoryName, setInventoryName] = useState();
  const [quantity, setQuantity] = useState();

  const { data } = useGetRequest("receiving/details/" + stockMovementID);

  const { status: statusCustomers, data: dataCustomers } = useGetRequest(
    "customers",
    {}
  );

  const dateFunction = (date) => {
    if (!date) return;
    let dateTimeSplit = date.split(" ");
    let newDate = dateTimeSplit[0].split("-");
    return newDate[0] + "-" + newDate[1] + "-" + newDate[2];
  };

  useEffect(() => {
    setReceivingDate(dateFunction(data?.data?.receivingDate));
    setCarrierName(data?.data?.carrierName);
    setVendorName(data?.data?.vendorName);
    setVendorAddress(data?.data?.vendorAddress);
    setCity(data?.data?.city);
    setState(data?.data?.state);
    setPhone(data?.data?.phone);
    setZip(data?.data?.zip);
    setCustomerName(data?.data?.customerName);
    setCustomerID(data?.data?.customerName);
    setInventoryName(
      data?.data?.productCode + " -- " + data?.data?.description
    );
    setInventoryID(data?.data?.inventoryID);
    setShelf(data?.data?.shelfData.length > 0 ? 1 : 0);
    setShelfData(data?.data?.shelfData);
    setQuantity(data?.data?.quantity);
  }, [data]);

  const updateReceiving = async (path, body) => {
    const res = await sendPostRequest(path, body);
    props.updatedNotyf();
  };

  const { mutateAsync: doUpdateMutation } = useMutation({
    mutationFn: (data) => {
      return updateReceiving(data.path, { stockMovementID, ...data.body });
    },
    onSuccess: () => {
      props.queryClient.invalidateQueries({ queryKey: ["receiving"] });
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await doUpdateMutation({
        path: "receiving/update",
        body: {
          stockMovementID: props.stockMovementID,
          receivingDate: receivingDate,
          carrierName: carrierName,
          vendorName: vendorName,
          vendorAddress: vendorAddress,
          city: city,
          state: state,
          phone: phone,
          zip: zip,
          customerID: customerID,
          inventoryID: inventoryID,
        },
      });
      props.setEditOpen(false);

      setTimeout(() => {
        window.location.reload(false);
      }, 500);
    } catch (e) {
      props.errorNotyf();
    }
  };

  if (statusCustomers == "fetched" && data) {
    return (
      <Transition.Root show={props.editOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={props.setEditOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <form onSubmit={handleSubmit}>
                      <div className="flex h-full flex-col  bg-white shadow-xl">
                        <div className="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
                          <div className="flex items-center justify-between">
                            <Dialog.Title className="text-lg font-medium text-gray-900"></Dialog.Title>
                            <div className="ml-3 flex h-7 items-center">
                              <button
                                type="button"
                                className="-m-2 p-2 text-gray-400 hover:text-gray-500"
                                onClick={() => props.setEditOpen(false)}
                              ></button>
                            </div>
                          </div>

                          <table className="min-w-full divide-y divide-gray-300">
                            <tbody className="divide-gray-200">
                              <tr>
                                <td className="w-1/3">
                                  {" "}
                                  <label
                                    htmlFor="receivingDate"
                                    className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                                  >
                                    Date Received
                                  </label>
                                </td>
                                <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500 w-2/3">
                                  <input
                                    type="date"
                                    name="receivingDate"
                                    value={receivingDate ? receivingDate : ""}
                                    onChange={(e) => {
                                      setReceivingDate(e.target.value);
                                    }}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className="w-1/3">
                                  {" "}
                                  <label
                                    htmlFor="carrierName"
                                    className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                                  >
                                    Carrier Name
                                  </label>
                                </td>
                                <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500 w-2/3">
                                  <input
                                    type="text"
                                    name="carrierName"
                                    value={carrierName ? carrierName : ""}
                                    onChange={(e) => {
                                      setCarrierName(e.target.value);
                                    }}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className="w-1/3">
                                  {" "}
                                  <label
                                    htmlFor="vendorName"
                                    className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                                  >
                                    Vendor Name
                                  </label>
                                </td>
                                <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500 w-2/3">
                                  <input
                                    type="text"
                                    name="vendorName"
                                    value={vendorName ? vendorName : ""}
                                    onChange={(e) => {
                                      setVendorName(e.target.value);
                                    }}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className="w-1/3">
                                  {" "}
                                  <label
                                    htmlFor="vendorAddress"
                                    className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                                  >
                                    Vendor Address
                                  </label>
                                </td>
                                <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500 w-2/3">
                                  <input
                                    type="text"
                                    name="vendorAddress"
                                    value={vendorAddress ? vendorAddress : ""}
                                    onChange={(e) => {
                                      setVendorAddress(e.target.value);
                                    }}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className="w-1/3">
                                  {" "}
                                  <label
                                    htmlFor="city"
                                    className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                                  >
                                    City
                                  </label>
                                </td>
                                <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500 w-2/3">
                                  <input
                                    type="text"
                                    name="city"
                                    value={city ? city : ""}
                                    onChange={(e) => {
                                      setCity(e.target.value);
                                    }}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className="w-1/3">
                                  {" "}
                                  <label
                                    htmlFor="state"
                                    className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                                  >
                                    State
                                  </label>
                                </td>
                                <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500 w-2/3">
                                  <input
                                    type="text"
                                    name="state"
                                    value={state ? state : ""}
                                    onChange={(e) => {
                                      setState(e.target.value);
                                    }}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className="w-1/3">
                                  {" "}
                                  <label
                                    htmlFor="phone"
                                    className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                                  >
                                    Phone
                                  </label>
                                </td>
                                <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500 w-2/3">
                                  <input
                                    type="text"
                                    name="phone"
                                    value={phone ? phone : ""}
                                    onChange={(e) => {
                                      setPhone(e.target.value);
                                    }}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className="w-1/3">
                                  {" "}
                                  <label
                                    htmlFor="zip"
                                    className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                                  >
                                    Zip
                                  </label>
                                </td>
                                <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500 w-2/3">
                                  <input
                                    type="text"
                                    name="zip"
                                    value={zip ? zip : ""}
                                    onChange={(e) => {
                                      setZip(e.target.value);
                                    }}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </td>
                              </tr>
                              {/* Shelf data: {shelfData} */}
                              <tr>
                                <td className="w-1/3">
                                  {" "}
                                  <label
                                    htmlFor="customer"
                                    className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                                  >
                                    Customer
                                  </label>
                                </td>
                                <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500 w-2/3">
                                  <input
                                    type="text"
                                    name="customer"
                                    disabled
                                    value={customerName ? customerName : ""}
                                    className="bg-gray-300 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className="w-1/3">
                                  {" "}
                                  <label
                                    htmlFor="inventoryName"
                                    className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                                  >
                                    Inventory Item
                                  </label>
                                </td>
                                <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500 w-2/3">
                                  <input
                                    type="text"
                                    name="inventoryName"
                                    disabled
                                    value={inventoryName ? inventoryName : ""}
                                    className="bg-gray-300 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className="w-1/3">
                                  {" "}
                                  <label
                                    htmlFor="quantity"
                                    className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                                  >
                                    Quantity
                                  </label>
                                </td>
                                <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500 w-2/3">
                                  <input
                                    type="text"
                                    name="quantity"
                                    disabled
                                    value={quantity ? quantity : ""}
                                    className="bg-gray-300 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </td>
                              </tr>
                              {/* <tr>
                                <td>
                                  {" "}
                                  <label
                                    htmlFor="shelf"
                                    className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 py-4"
                                  >
                                    Shelf
                                  </label>
                                </td>
                                <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                                  <input
                                    type="checkbox"
                                    name="shelf"
                                    id="shelf"
                                    checked={shelf === 1 ? true : false}
                                    onChange={(e) => {
                                      setShelf(e.target.checked ? 1 : 0);
                                    }}
                                    className="block w-4 rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </td>
                              </tr> */}
                              {/* {dataLocations.status !== "success" ? null : (
                              <>
                                <tr>
                                  <td>
                                    {" "}
                                    <label
                                      htmlFor="location"
                                      className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                                    >
                                      Location
                                    </label>
                                  </td>

                                  <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                                    <label className="relative">
                                      <input
                                        type="checkbox"
                                        className="hidden peer"
                                      />
                                      {"Locations (+/-)"}

                                      <div className="absolute bg-white border transition-opacity opacity-0 pointer-events-none peer-checked:opacity-100 peer-checked:pointer-events-auto">
                                        <ul className="grid grid-cols-3 w-64">
                                          {dataLocations.locations.map(
                                            (option, i) => {
                                              return (
                                                <li
                                                  className="mr-15"
                                                  key={option}
                                                >
                                                  <label className="flex whitespace-nowrap cursor-pointer px-1 py-1 transition-colors hover:bg-blue-100 [&:has(input:checked)]:bg-blue-200">
                                                    <input
                                                      type="checkbox"
                                                      name="locations"
                                                      value={
                                                        // option.locationID
                                                        option.name
                                                      }
                                                      onChange={() => {
                                                        addRemoveLocation(
                                                          // option.locationID
                                                          option.name
                                                        );
                                                      }}
                                                      className="cursor-pointer"
                                                    />
                                                    <span className="ml-1 text-xs font-medium">
                                                      {option.name}
                                                    </span>
                                                  </label>
                                                </li>
                                              );
                                            }
                                          )}
                                        </ul>
                                      </div>
                                    </label>
                                  </td>
                                </tr>
                              </>
                            )} */}

                              {shelf === 0 ? (
                                <>
                                  {/* {[...Array(skids)].map((page, i) => (
                                  <SkidRow
                                    id={i}
                                    skidData={skidData}
                                    setSkidData={setSkidData}
                                  />
                                ))}

                                <div className="mt-2">
                                  <button
                                    type="button"
                                    onClick={() => setSkids(skids + 1)}
                                    className="rounded-full bg-indigo-600 p-1 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                  >
                                    <PlusIcon
                                      className="h-3 w-3"
                                      aria-hidden="true"
                                    />
                                  </button>
                                  {skids > 1 ? (
                                    <button
                                      type="button"
                                      onClick={() =>
                                        setSkids(
                                          skids > 1 ? skids - 1 : skids - 0
                                        )
                                      }
                                      className="border-1 ml-1 rounded-full bg-indigo-600 p-1 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                      <MinusIcon
                                        className="h-3 w-3"
                                        aria-hidden="true"
                                      />
                                    </button>
                                  ) : null}
                                </div> */}
                                </>
                              ) : (
                                <>
                                  {/* {[...Array(shelfs)].map((page, i) => (
                                  <ShelfRow
                                    id={i}
                                    shelfData={shelfData}
                                    setShelfData={setShelfData}
                                  />
                                ))} */}

                                  {/* <div className="mt-2">
                                  <button
                                    type="button"
                                    onClick={() => setShelfs(shelfs + 1)}
                                    className="rounded-full bg-indigo-600 p-1 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                  >
                                    <PlusIcon
                                      className="h-3 w-3"
                                      aria-hidden="true"
                                    />
                                  </button>
                                  {shelfs > 1 ? (
                                    <button
                                      type="button"
                                      onClick={() =>
                                        setShelfs(
                                          shelfs > 1 ? shelfs - 1 : shelfs - 0
                                        )
                                      }
                                      className="border-1 ml-1 rounded-full bg-indigo-600 p-1 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                      <MinusIcon
                                        className="h-3 w-3"
                                        aria-hidden="true"
                                      />
                                    </button>
                                  ) : null}
                                </div> */}
                                </>
                              )}
                              {/* <tr>
                              <td>
                                {" "}
                                <label
                                  htmlFor="unitWeight"
                                  className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                                >
                                  Unit weight (lbs)
                                </label>
                              </td>
                              <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                                <input
                                  type="number"
                                  name="unitWeight"
                                  onChange={handleChange}
                                  required="required"
                                  value={inputs.unitWeight || ""}
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </td>
                            </tr> */}
                            </tbody>
                          </table>

                          {/* <table className="min-w-full">
                          <tbody>
                            {shelf === 0 && inputs.unitWeight ? (
                              <>
                                <tr>
                                  <td className="text-sm text-gray-500 w-1/3">
                                    Total quantity:
                                  </td>
                                  <td className="text-sm text-gray-500 w-2/3 px-3">
                                    {getSkidTotalQuantity(skidData, skids)}
                                  </td>
                                </tr>

                                <tr>
                                  <td className="text-sm text-gray-500 w-1/3">
                                    Total weight:
                                  </td>
                                  <td className="text-sm text-gray-500 w-2/3 px-3">
                                    {getSkidTotalQuantity(skidData, skids) *
                                      inputs.unitWeight}{" "}
                                    lbs
                                  </td>
                                </tr>

                                <tr>
                                  <td className="text-sm text-gray-500 w-1/3">
                                    Total charge:
                                  </td>
                                  <td className="text-sm text-gray-500 w-2/3 px-3">
                                    {getSkidProcessingCharge(
                                      locations.length,
                                      getSkidTotalQuantity(skidData, skids),
                                      inputs.unitWeight,
                                      charge10,
                                      charge9,
                                      charge8
                                    )}
                                  </td>
                                </tr>
                              </>
                            ) : null}

                            {shelf === 1 && inputs.unitWeight ? (
                              <>
                                <tr className="py-5">
                                  <td className="text-sm text-gray-500 w-1/3">
                                    Total quantity:
                                  </td>

                                  {shelf === 0 ? null : (
                                    <td className="text-sm text-gray-500 w-2/3 px-3">
                                      {getShelfTotalQuantity(shelfData, shelfs)}
                                    </td>
                                  )}
                                </tr>

                                <tr className="py-5">
                                  <td className="text-sm text-gray-500 w-1/3">
                                    Total weight:
                                  </td>

                                  {shelf === 0 ? null : (
                                    <td className="text-sm text-gray-500 w-2/3 px-3">
                                      {getShelfTotalQuantity(
                                        shelfData,
                                        shelfs
                                      ) * inputs.unitWeight}
                                    </td>
                                  )}
                                </tr>

                                <tr className="py-5">
                                  <td className="text-sm text-gray-500 w-1/3">
                                    Customer charge:
                                  </td>

                                  {shelf === 0 ? null : (
                                    <td className="text-sm text-gray-500 w-2/3 px-3">
                                      $
                                      {getShelfProcessingCharge(
                                        inputs.unitWeight,
                                        charge10,
                                        charge9,
                                        getShelfTotalQuantity(shelfData, shelfs)
                                      )}
                                    </td>
                                  )}
                                </tr>
                              </>
                            ) : null}
                          </tbody>
                        </table> */}

                          <div className="mt-5 pb-4">
                            <button
                              type="submit"
                              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }
}
