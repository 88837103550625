import { React, useEffect, useState } from "react";
import Navbar from "../../Components/Navbar";
import Topbar from "../../Components/Topbar";
import { useParams } from "react-router-dom";
import { sendGetRequest, sendPostRequest, useGetRequest } from "../../Client";
import ReactLoading from "react-loading";
import { Notyf } from "notyf";
import "notyf/notyf.min.css"; // for React, Vue and Svelte
import Swal from "sweetalert2";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import CustomerInventoryAdd from "./add";
import CustomerInventoryUpdate from "./update";
import CustomersBreadcrumb from "../../Components/CustomersBreadcrumb";
import { Link } from "react-router-dom";
import CustomerName from "../../Components/CustomerName";

function CustomerInventory() {
  const { customerID } = useParams();
  const { status, data } = useGetRequest("customer/" + customerID, {});

  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);

  const queryClient = useQueryClient();

  const [productInventoryID, setProductInventoryID] = useState();
  const [productTypeCode, setProductTypeCode] = useState();
  const [productCategory, setProductCategory] = useState();

  const [divisionID, setDivisionID] = useState();
  const [divisionName, setDivisionName] = useState();
  const [category1ID, setCategory1ID] = useState();
  const [category1Name, setCategory1Name] = useState();
  const [category2ID, setCategory2ID] = useState();
  const [category2Name, setCategory2Name] = useState();
  const [category3ID, setCategory3ID] = useState();
  const [category3Name, setCategory3Name] = useState();

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  // confirm delete
  const areYouSure = (id) => {
    Swal.fire({
      title: "Are you sure?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#de3333",
    }).then((result) => {
      if (result.isConfirmed) {
        doDeleteMutation(id);
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  // confirmation notifications
  const notyf = new Notyf({
    position: {
      x: "right",
      y: "top",
    },
  });

  const updatedNotyf = () => {
    notyf.success("Inventory updated");
  };

  const addedNotyf = () => {
    notyf.success("Inventory added");
  };

  const deletedNotyf = () => {
    notyf.success({ message: "Inventory deleted", background: "#FF0000" });
  };

  const { status: statusDropdownCategories, data: dataDropdownCategories } =
    useGetRequest("inventory/categories/" + customerID, {});

  const { status: statusDropdownDivisions, data: dataDropdownDivisions } =
    useGetRequest("inventory/divisions/" + customerID, {});

  // loading inventory
  const { data: inventory, isLoading } = useQuery({
    queryFn: () => sendGetRequest("inventory/" + customerID, {}),
    queryKey: ["inventory"],
  });

  // deleting inventory
  const inventoryDelete = async (id) => {
    const res = await sendPostRequest("inventory/delete", {
      inventoryID: id,
    });
  };

  const { mutateAsync: doDeleteMutation } = useMutation({
    mutationFn: (id) => {
      return inventoryDelete(id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["inventory"] });
      deletedNotyf();
    },
  });

  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    setFilteredDataSource(inventory?.inventory);
  }, [inventory]);

  const searchFilterFunction = (text) => {
    if (text) {
      const newData = inventory.inventory.filter(function (item) {
        const allValues = item.productCode + item.description;
        const itemData = allValues ? allValues : "";
        const textData = text;
        return itemData.indexOf(textData) > -1;
      });
      setFilteredDataSource(newData);
      setSearch(text);
    } else {
      setFilteredDataSource(inventory?.inventory);
      setSearch(text);
    }
  };

  if (statusDropdownCategories == "fetched") {
    return (
      <>
        <div className="flex flex-row w-full">
          <Navbar />

          <div className="flex flex-col w-4/5 bg-gray-100 ml-80">
            <Topbar />

            <CustomersBreadcrumb />

            <CustomerName customerName={data?.customer?.customerName} />

            <div className="bg-white px-4 sm:px-6 lg:px-8 border border-gray-200 ml-10 mr-10 mt-10 mb-40">
              <form>
                <div className="space-y-12">
                  <div className="mt-10">
                    <div className="sm:flex sm:items-center">
                      <div className="sm:flex-auto">
                        <h1 className="text-2xl font-semibold leading-6 text-gray-900 mb-5">
                          Inventory ({filteredDataSource?.length})
                        </h1>
                      </div>

                      <div>
                        <input
                          className="ml-4 mt-2 px-3 py-3.5 text-left text-sm font-medium text-grey-500"
                          type="text"
                          style={{
                            borderRadius: 20,
                            borderColor: "gray",
                            width: 200,
                          }}
                          onChange={(text) =>
                            searchFilterFunction(text.target.value)
                          }
                          placeholder="Search"
                          value={search}
                        ></input>
                      </div>

                      <Link to={`/divisions/${customerID}`}>
                        <div className="mt-4 sm:ml-4 sm:mt-0 sm:flex-none">
                          <button
                            type="button"
                            className="block rounded-md bg-teal-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                          >
                            Manage Divisions
                          </button>
                        </div>
                      </Link>

                      <div className="mt-4 sm:ml-4 sm:mt-0 sm:flex-none">
                        <button
                          type="button"
                          onClick={() => setOpen(true)}
                          className="block rounded-md bg-green-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                        >
                          Add Item
                        </button>
                      </div>
                    </div>
                    <div className="mt-8 flow-root mb-10">
                      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                          <table className="min-w-full divide-y divide-gray-300">
                            <thead>
                              <tr>
                                <th
                                  scope="col"
                                  className="w-1/6 py-3.5 pl-4 pr-3 text-left text-sm font-medium text-gray-900 sm:pl-0"
                                >
                                  Product Code
                                </th>
                                <th
                                  scope="col"
                                  className="w-1/3 px-3 py-3.5 text-left text-sm font-medium text-gray-900"
                                >
                                  Product Description
                                </th>
                                <th
                                  scope="col"
                                  className="w-1/5 px-3 py-3.5 text-left text-sm font-medium text-gray-900"
                                >
                                  Locations
                                </th>
                                <th
                                  scope="col"
                                  className="w-1/5 px-3 py-3.5 text-left text-sm font-medium text-gray-900"
                                >
                                  Division
                                </th>
                                <th
                                  scope="col"
                                  className="w-1/6 px-3 py-3.5 text-left text-sm font-medium text-gray-900"
                                >
                                  Price
                                </th>
                                <th
                                  scope="col"
                                  className="w-1/6 px-3 py-3.5 text-left text-sm font-medium text-gray-900"
                                >
                                  Stock
                                </th>
                                <th
                                  scope="col"
                                  className="w-1/4 px-3 py-3.5 text-left text-sm font-medium text-gray-900"
                                >
                                  Admin Only
                                </th>
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-medium text-gray-900"
                                ></th>
                              </tr>
                            </thead>
                            {!isLoading ? (
                              // inventory.inventory?.length > 0 ? (
                              filteredDataSource?.length > 0 ? (
                                <tbody className="divide-y divide-gray-200">
                                  {/* {inventory.inventory?.map((item) => ( */}
                                  {filteredDataSource?.map((item) => (
                                    <tr key={item.inventoryID}>
                                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-700 sm:pl-0">
                                        {item.productCode}
                                      </td>
                                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        {item.description}
                                      </td>
                                      <td className="whitespace-wrap px-3 py-4 text-sm text-gray-500">
                                        {item.defaultLocation}
                                      </td>
                                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        {item.divisionName}
                                      </td>
                                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        ${numberWithCommas(item.unitPrice)}
                                      </td>
                                      <Link
                                        to={`/stock/${customerID}/${item.inventoryID}`}
                                      >
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-blue-500 underline">
                                          {numberWithCommas(
                                            (item.stockIn == null
                                              ? 0
                                              : item.stockIn) -
                                              (item.stockOut == null
                                                ? 0
                                                : item.stockOut) +
                                              (item.importStockLevel == null
                                                ? 0
                                                : item.importStockLevel)
                                          )}
                                        </td>
                                      </Link>
                                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        {item.adminOnly === 1 ? "Yes" : ""}
                                      </td>

                                      <td className="block text-right">
                                        <span className="mt-2 isolate inline-flex rounded-md shadow-sm">
                                          <button
                                            type="button"
                                            onClick={() => {
                                              setProductInventoryID(
                                                item.inventoryID
                                              );
                                              setProductTypeCode(item.typeCode);
                                              setProductCategory(item.category);
                                              setDivisionID(item.division);
                                              setDivisionName(
                                                item.divisionName
                                              );
                                              setCategory1ID(item.category1);
                                              setCategory1Name(
                                                item.category1Name
                                              );
                                              setCategory2ID(item.category2);
                                              setCategory2Name(
                                                item.category2Name
                                              );
                                              setCategory3ID(item.category3);
                                              setCategory3Name(
                                                item.category3Name
                                              );
                                              setEditOpen(true);
                                            }}
                                            className="relative inline-flex items-center rounded-l-md bg-amber-500 hover:bg-amber-400 focus-visible:outline-amber-600 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 focus:z-10"
                                          >
                                            Edit
                                          </button>

                                          <button
                                            type="button"
                                            onClick={() => {
                                              if (
                                                areYouSure(item.inventoryID)
                                              ) {
                                                inventoryDelete(
                                                  item.inventoryID
                                                );
                                              }
                                            }}
                                            className="relative -ml-px inline-flex items-center rounded-r-md bg-red-500 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 hover:bg-red-400 focus:z-10"
                                          >
                                            Delete
                                          </button>
                                        </span>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              ) : (
                                <>
                                  <tbody className="divide-y divide-gray-200">
                                    <tr>
                                      <td
                                        colSpan="4"
                                        className="w-full text-center whitespace-nowrap px-3 py-4 text-sm text-gray-500 col-span-6"
                                      >
                                        -- No items found --
                                      </td>
                                    </tr>
                                  </tbody>
                                </>
                              )
                            ) : (
                              <>
                                <tbody className="divide-y divide-gray-200">
                                  <tr>
                                    <td
                                      colSpan="5"
                                      className="w-full text-center whitespace-nowrap px-3 py-4 text-sm text-gray-500 col-span-6"
                                    >
                                      <ReactLoading
                                        type={"spin"}
                                        color="#5046e5"
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </>
                            )}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <CustomerInventoryAdd
          open={open}
          setOpen={setOpen}
          dataDropdownDivisions={dataDropdownDivisions}
          addedNotyf={addedNotyf}
          customerID={customerID}
          queryClient={queryClient}
        />

        {/* {productInventoryID && divisionID && ( */}
        {productInventoryID && (
          <CustomerInventoryUpdate
            editOpen={editOpen}
            setEditOpen={setEditOpen}
            dataDropdownCategories={dataDropdownCategories}
            updatedNotyf={updatedNotyf}
            customerID={customerID}
            queryClient={queryClient}
            productInventoryID={productInventoryID}
            productTypeCode={productTypeCode}
            productCategory={productCategory}
            dataDropdownDivisions={dataDropdownDivisions}
            divisionID={divisionID}
            divisionName={divisionName}
            category1ID={category1ID}
            category1Name={category1Name}
            category2ID={category2ID}
            category2Name={category2Name}
            category3ID={category3ID}
            category3Name={category3Name}
          />
        )}
      </>
    );
  }
}

export default CustomerInventory;
