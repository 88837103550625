import { React, useEffect } from "react";
import Navbar from "../../Components/Navbar";
import Topbar from "../../Components/Topbar";
import { useState } from "react";
import { sendPostRequest, useGetRequest } from "../../Client";
import ReactLoading from "react-loading";
import * as XLSX from "xlsx";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function InventoryUsageTerritory() {
  const [searchCustomer, setSearchCustomer] = useState("");
  const [searchDivision, setSearchDivision] = useState("");
  const [searchFromDate, setSearchFromDate] = useState("");
  const [searchToDate, setSearchToDate] = useState("");

  const [loading, setLoading] = useState(false);

  const { status: statusDropdownStatuses, data: dataDropdownStatuses } =
    useGetRequest("statuses/list", {});

  const { status: statusCustomers, data: dataCustomers } = useGetRequest(
    "customers",
    {}
  );

  const { status: statusDivisions, data: dataDivisions } = useGetRequest(
    "inventory/divisions/" + searchCustomer,
    {}
  );

  const downloadReport = async (finalData) => {

    if(!searchCustomer) {
        alert("Please select a customer");
        return;
    }

    if(!finalData.length) {
        alert("No data found");
        return;
    }

    const worksheet = XLSX.utils.json_to_sheet(finalData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "inventory-usage-territory.xlsx");
  };

  const fetchData = async () => {

    const requestBody = {
      customer: searchCustomer,
      division: searchDivision,
      fromDate: searchFromDate,
      toDate: searchToDate,
    };

    const res = await sendPostRequest("reports/inventory-usage", requestBody);

    const excelData = [];

    {res.reportData?.map((item) => (
        excelData.push({customer: item.customer, division: item.division, itemCode: item.productCode, description: item.description, balance: 100})   
    ))}

    downloadReport(excelData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchData();
  };

  if (statusDropdownStatuses == "fetched" && statusCustomers == "fetched") {
    return (
      <>
        <div className="flex flex-row w-full">
          <Navbar />

          <div className="flex flex-col w-4/5 bg-gray-100 mb-100 ml-80">
            <Topbar />

            <div className="flex justify-center text-2xl font-bold mt-8">
              Inventory Usage by Territory
            </div>

            {/* content */}
            <div className="bg-white px-4 sm:px-6 lg:px-8 border border-gray-200 ml-10 mr-10 mt-10 mb-60">
              <form onSubmit={handleSubmit}>
                <div className="border px-2 py-2 mt-5">
                  <div className="w-full flex flex-row mt-4 justify-center align-end">
                    <div className="w-1/4">
                      <div className="mb-5 mx-2">
                        <label
                          htmlFor="customer"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Customer
                        </label>
                        <div className="mt-2">
                          <select
                            id="customer"
                            name="customer"
                            onChange={(e) => setSearchCustomer(e.target.value)}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                          >
                            <option></option>
                            {dataCustomers.customers.map((customer) => (
                              <option value={customer.customerID}>
                                {customer.customerName}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    {dataDivisions.status !== "success" ? null : (
                      <div className="w-1/4">
                        <div className="mb-5 mx-2">
                          <label
                            htmlFor="division"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Division
                          </label>
                          <div className="mt-2">
                            <select
                              id="division"
                              name="division"
                              onChange={(e) =>
                                setSearchDivision(e.target.value)
                              }
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                            >
                              <option></option>
                              {dataDivisions.divisions.map((division) => (
                                <option value={division.divisionID}>
                                  {division.divisionName}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="w-1/4">
                      <div className="mb-5 mx-2">
                        <div className="sm:col-span-2">
                          <label
                            htmlFor="fromDate"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            From Date
                          </label>
                          <div className="mt-2">
                            <input
                              type="date"
                              name="fromDate"
                              id="fromDate"
                              onChange={(e) =>
                                setSearchFromDate(e.target.value)
                              }
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-1/4">
                      <div className="sm:col-span-2">
                        <label
                          htmlFor="toDate"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          To Date
                        </label>
                        <div className="mt-2">
                          <input
                            type="date"
                            name="toDate"
                            id="toDate"
                            onChange={(e) => setSearchToDate(e.target.value)}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="w-full grid justify-items-end">
                    <button
                      type="submit"
                      className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Search
                    </button>
                  </div>
                </div>
              </form>

              {loading ? (
                <div className="inline-block min-w-full py-2 flex justify-center sm:px-6 lg:px-8">
                  <ReactLoading type={"spin"} color="#5046e5" />
                </div>
              ) : null}
            </div>
            {/* content */}
          </div>
        </div>
      </>
    );
  }
}

export default InventoryUsageTerritory;
